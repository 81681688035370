/**
 *
 * @param {HTMLElement} navigationWrapper
 * @param {HTMLInputElement} toggleNavigationBtn
 */
export default function (navigationWrapper, toggleNavigationBtn) {

  const documentHeight = document.body.getBoundingClientRect().height;
  const windowHeight = window.innerHeight;

  toggleNavigationBtn.checked = false;

  function toggleNavigation() {
    document.body.classList.toggle('masked');
    navigationWrapper.classList.toggle('open');

    if (navigationWrapper.classList.contains('open')) {
      if (documentHeight > windowHeight) {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.classList.add('disable-scroll');
      }
    } else {
      document.body.classList.remove('disable-scroll');
      window.scrollTo({
        top: -parseInt(document.body.style.top.substring(-2))
      });
    }
  }

  toggleNavigationBtn.addEventListener('change', toggleNavigation);

  const closeNavigationBtn = document.getElementById('close-main-navigation');
  closeNavigationBtn.addEventListener('mousedown', toggleNavigation);

  window.addEventListener('keydown', (e) => {
    if (e.key === 'Esc' || e.key === 'Escape') {
      if (navigationWrapper.classList.contains('open')) {
        toggleNavigationBtn.checked = false;
        const event = new Event('change');
        toggleNavigationBtn.dispatchEvent(event);
      }
    }
  });

  /*let links = document.querySelectorAll('#main-navigation li.has-children > a');
  const subMenus = document.querySelectorAll('.sub-navigation-wrapper');
  links.forEach(link => {
    link.addEventListener('click', (e) => {
      const linkLi = link.parentNode;
      if (linkLi.classList.contains('active')) {
        return true;
      }

      const subMenu = document.querySelector(`.sub-navigation-wrapper[data-parent-node="menu-item-${linkLi.dataset.menuItemId}"]`);

      if (subMenu) {
        e.preventDefault();

        subMenus.forEach(sub => {
          if (sub === subMenu) {
            sub.classList.add('active');
          }
          else {
            sub.classList.remove('active');
          }
        });

        linkLi.classList.add('active');
        for (let sibling of linkLi.parentNode.children) {
          if (sibling !== linkLi) {
            sibling.classList.remove('active');
          }
        }

        return false;
      }

      return true;
    });
  });*/
};
