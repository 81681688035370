export default function () {
  // store the scrollbar width as CSS variable
  let rafTimer;

  function storeScrollbarWidth() {
    const documentRootNode = document.documentElement;
    const scrollbarWidth = window.innerWidth - documentRootNode.clientWidth;
    documentRootNode.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
  }

  window.requestAnimationFrame(() => {
    storeScrollbarWidth();
  });

  window.addEventListener('resize', () => {
    cancelAnimationFrame(rafTimer);
    rafTimer = requestAnimationFrame(storeScrollbarWidth);
  });
};
