import '../css/app.scss';

import scrollbar from './helper/scrollbar';
scrollbar();

// header
import('./components/header').then(module => {
  module.default();
});

// tooltips
import { Tooltip } from 'bootstrap';

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));

// animate elements entering the viewport
// import { animon } from 'animon';
//
// animon();


import AOS from 'aos';
AOS.init();

// navigation
import navigation from './components/navigation';
const navigationWrapper = document.getElementById('main-navigation-wrapper');
const toggleNavigationBtn = document.getElementById('nav-btn');
navigation(navigationWrapper, toggleNavigationBtn);

// popups
const dialog = document.querySelector('.dialog');
if (dialog) {
  import('./components/dialog');
}